import React, { useEffect, useState } from "react";
import { LoginError } from "../components/TWComponents/LoginError";

import Layout from "../layouts/ja";
import SEO from "../components/seo";

const LoginErrorPage = (props) => {
  const [description, setDescription] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const queryParams = new URLSearchParams(window.location.search);
      setDescription(queryParams.get("error_description"));
    }
  }, []);

  return (
    <Layout location={props.location} showBookNowButton={false}>
      <SEO
        title="Login Failed"
        keyword="Login Failed"
        decription="Login Failed"
      />
      <LoginError
        title="ログイン失敗"
        description={description || "No error description available."}
        subDescription="ログインリクエストの処理中に問題が発生しました。再試行するか、この問題が続く場合はサポートにご連絡ください。"
        showErrorDetails="エラーディテールを表示"
        hideErrorDetails="エラーディテールを非表示"
        needAssistance="サポートが必要ですか?"
        contactSupport="サポートに連絡"
      />
    </Layout>
  );
};

export default LoginErrorPage;
