import React, { useState } from "react";
import { Link } from "gatsby";

interface LoginErrorProps {
  locale?: string;
  title?: string;
  subDescription?: string;
  description?: string;
  trackingId?: string;
  showErrorDetails?: string;
  hideErrorDetails?: string;
  invalidRequest?: string;
  needAssistance?: string;
  contactSupport?: string;
}

export const LoginError = ({
  title,
  subDescription,
  description,
  showErrorDetails,
  hideErrorDetails,
  invalidRequest,
  needAssistance,
  contactSupport,
}) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <div className="tw-antialiased lg:tw-max-w-[400px] lg:tw-mx-auto lg:tw-border-[#C2C8D0]/30 lg:tw-border lg:tw-border-solid lg:tw-rounded-md lg:tw-mt-[64px] !tw-font-ayana">
      <div className="tw-py-[56px] tw-px-[40px]">
        {/* Title */}
        <h2 className="tw-text-[22px] tw-leading-[33px] !tw-font-bold tw-text-center !tw-font-ayana !tw-text-[#3E3A39] tw-py-3">
          {title}
        </h2>

        {/* Content */}
        <div className="tw-flex tw-flex-col tw-gap-6">
          <p className="tw-text-sm tw-leading-5 !tw-font-ayana tw-m-0 !tw-text-[#3E3A39]">
            {subDescription}
          </p>

          {/* Error Details Toggle */}
          <button
            onClick={() => setShowDetails(!showDetails)}
            className="tw-text-[#0EA5E9] hover:tw-text-[#0284C7] tw-text-sm !tw-font-ayana tw-bg-transparent tw-self-start tw-p-0 !tw-font-bold">
            {showDetails ? hideErrorDetails : showErrorDetails}
          </button>

          {/* Error Details */}
          {showDetails && (
            <div className="tw-bg-[#E4F6FC] tw-p-4 tw-rounded-[10px]">
              <p className="tw-text-sm tw-leading-5 !tw-font-ayana tw-m-0 !tw-text-[#3E3A39]">
                <span className="!tw-font-bold">invalid_request:</span>{" "}
                {description}
              </p>
            </div>
          )}

          {/* Support Link */}
          <p className="tw-text-sm tw-leading-5 !tw-font-ayana tw-m-0 !tw-text-[#3E3A39]">
            {needAssistance}{" "}
            <a
              href="mailto:info@ayanarewards.com?subject=AYANA%20Rewards%20Login%20Issue"
              className="tw-text-[#0EA5E9] hover:tw-text-[#0284C7] !tw-font-bold">
              {contactSupport}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginError;
